import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
    // Use toast
    const toast = useToast()

    const refInvoiceListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'index', label: '#' },

        { key: 'actions' , label : 'อัพเดท' },
      
        {
            key: "license",
            label: "ทะเบียนรถ",
        },
        {
            key: "round",
            label: "รอบ",
        },
        // {
        //     key: "route",
        //     label: "สาย",


        // },


        {
            key: "order_date",
            label: "วันที่สั่งสินค้า",

        },
        {
            key: "transfer_date",
            label: "วันที่ส่งสินค้า",

        },
       
        {
            key: "shop_name",
            label: "ร้านค้า",

        },
        {
            key: "size_small",
            label: "สินค้า(เล็ก)",
        },
        {
            key: "size_middle",
            label: "สินค้า(กลาง)",
        },
        {
            key: "size_large",
            label: "สินค้า(ใหญ่)",
        },
        {
            key: "remark",
            label: "อื่นๆ",
        },
        {
            key: "total",
            label: "รวม",
            // sortable: true

        },
        // {aline
        //     key: "total",
        //     label: "NO.INVOICE",
        // },
        {
            key: "invoice",
            label: "ใบแจ้งหนี้",
        },
        {
            key: "amount",
            label: "จำนวนเงิน",
        },
        {
            key: "status",
            label: "สถานะ",
        },
        {
            key: 'status_main' ,
            label:'อัพเดท สถานะ' 
           }
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        // { key: 'actions' },
        // { key: 'client', sortable: true },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'issuedDate', sortable: true },
        // { key: 'Endate', sortable: true },
        // { key: 'Endate1', sortable: true },
        // { key: 'Endate2', sortable: true },
        // { key: 'balance', sortable: true },


   

    ]
    const tableColumnsrows = [
        {
            key: "shop_code",
            label: "รหัสรายการ",
        },
        {
            key: "transfer_zone",
            label: "สาย",


        },
        {
            key: "order_date",
            label: "วันส่งสินค้า",

        },
        {
            key: "code",
            label: "รหัส",
        },
        {
            key: "shop_name",
            label: "ร้านค้า",

        },
        {
            key: "size_small",
            label: "สินค้า(เล็ก)",
        },
        {
            key: "size_middle",
            label: "สินค้า(กลาง)",
        },
        {
            key: "size_large",
            label: "สินค้า(ใหญ่)",
        },
        {
            key: "remark",
            label: "อื่นๆ",
        },
        {
            key: "total",
            label: "รวม",
            // sortable: true

        },
        // {
        //     key: "code",
        //     label: "NO.INVOICE",

        // },
        {
            key: "status_order",
            label: "สถานะ",
        },
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        // { key: 'actions' },
        // { key: 'client', sortable: true },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'issuedDate', sortable: true },
        // { key: 'Endate', sortable: true },
        // { key: 'Endate1', sortable: true },
        // { key: 'Endate2', sortable: true },
        // { key: 'balance', sortable: true },

    ]


    const date = new Date();
    // const date = new Date();
    const [month, day, year] = [
        date.getMonth(),
        date.getDate(),
        date.getFullYear(),
    ];



    // date = [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}];
    // let s = join(new Date, a, '-');
    // console.log(s);


    const perPage = ref(10)
    const totalInvoices = ref(0)
    const totalInvoices1 = ref([])
    const excel= ref([])

    const totalInvoices2 = ref(0)
    const totalInvoices3 = ref(0)
    const totalInvoices4 = ref(0)
    const totalInvoices5 = ref(0)
    const check_status_payment = ref([])
    const params = ref('')
  
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const searchStartDate = ref('')
    // const searchStartDate = ref(year+'-'+month+"-"+day)

    const start = Date.now();

    const searchEndDate = ref('')

    const searchEndDate1 = ref(1)
    const currentPage = ref(30)

    const round = ref('')
    const license = ref('')
    const searchStatus = ref('รอจัดส่ง')
    const searchRout = ref('')

    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
        return {
            from: currentPage.value * (searchEndDate1.value - 1) + (localItemsCount ? 1 : 0),
            to: currentPage.value * (searchEndDate1.value - 1) + localItemsCount,
            of: totalInvoices.value,
        }
    })

    const refetchData = () => {
        refInvoiceListTable.value.refresh()
    }

    watch([currentPage,license,round, perPage, searchQuery, statusFilter, searchStartDate, searchEndDate, searchEndDate1, searchStatus, searchRout], () => {
        refetchData()
    })

    const fetchInvoices = (ctx, callback) => {



        // console.log("year" , year)
        // console.log("month" , month)
        // console.log("day" , day)


        // if(month < 10){
        //     console.log("1")
        //     month = `0`+ month 
        // }

        // status
        // console.log("month1" , month +`0`)

        //     let payload  = {
        //     "dateFrom": searchStartDate.value ,
        //     "status" : "",
        //     "license" : "",
        //     "shopName" : "",
        //     "round" : ""
        // }
        let payload = { "dateFrom": searchStartDate.value ? searchStartDate.value : searchStartDate.value, 
        "dateTo": searchEndDate.value ? searchEndDate.value : searchStartDate.value,
         "status": searchStatus.value,
          "round": round.value,
         "license" : license.value,
           "shopName": statusFilter.value 
        }
        // let payload  = { }
        store
            .dispatch('app-invoice/listtransfer', payload

                //   {
                // q: searchQuery.value,
                // perPage: perPage.value,
                // page: currentPage.value,
                // sortBy: sortBy.value,
                // sortDesc: isSortDirDesc.value,
                // status: statusFilter.value,
                //   }

            )
            .then(response => {
                // const { invoices, total } = response
                var value = response.data
                for (let i = 0; i < response.data.length; i++) {
                    // console.log("testtttttttttttttttttt", response.data)
                    value[i].checkbox = false
                    value[i].index = i + 1
                }
                // console.log("total"  ,  value)
                // console.log("respone_test",   value)
                params.value = value

                let perPage  = currentPage.value 
                let page = searchEndDate1.value

                let records = [];
                const startIndex =  perPage * (page - 1) + 1;
                const endIndex = startIndex + perPage - 1;
                for (let i = startIndex; i <= endIndex; i++) {
                    if(params.value[i-1] == undefined){
                        // records.push({
                        //     checkpage : ''
                        // });
                    }else{
                        records.push(params.value[i-1]);
                     
                    }
                }
                
            
                let max_large = 0
                let max_middle = 0
                let max_small = 0

                for (let j = 0; j < params.value.length; j++) {
                    max_large = max_large + params.value[j].size_large
                    max_middle = max_middle + params.value[j].size_middle
                    max_small = max_small + params.value[j].size_small
                  }

                  console.log("max_large" ,max_large)
                  console.log("max_middle" ,max_middle)
                  console.log("max_small" ,max_small)
                


                for (let i = 0; i < records.length; i++) {
                    
                    if(records[i].invoice != `` && records[i].amount !=``) {
                        records[i].check_status_payment = true
                       
                        console.log("1" , i )  
                    }   else{
                        console.log("2" , i )  
                    }
                  }


                

                // console.log(records,"records");

                // console.log("recode , " , records)

                callback(records)
                totalInvoices.value = value.length
                totalInvoices1.value = records
                excel.value =  params.value

                totalInvoices2.value = max_small
                totalInvoices3.value = max_middle
                totalInvoices4.value = max_large
                totalInvoices5.value = max_large + max_middle + max_small
            })
            .catch(() => {
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching invoices' list",
                //         icon: 'AlertTriangleIcon',
                //         variant: 'danger',
                //     },
                // })

                // toast({
                //     component: ToastificationContent,
                //     props: {
                //       title: 'Event Updated',
                //       icon: 'CheckIcon',
                //       variant: 'success',
                //     },
                //   })

                // toast({
                //     component: ToastificationContent,
                //     props: {
                //       title: 'Event Removed',
                //       icon: 'TrashIcon',
                //       variant: 'danger',
                //     },
                //   })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveInvoiceStatusVariantAndIcon = status => {
        if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
        if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
        if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
        if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
        if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
        if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
        return { variant: 'secondary', icon: 'XIcon' }
    }

    const resolveClientAvatarVariant = status => {
        if (status === 'Partial Payment') return 'primary'
        if (status === 'Paid') return 'danger'
        if (status === 'Downloaded') return 'secondary'
        if (status === 'Draft') return 'warning'
        if (status === 'Sent') return 'info'
        if (status === 'Past Due') return 'success'
        return 'primary'
    }

    return {
        fetchInvoices,
        tableColumns,
        tableColumnsrows,
        perPage,
        currentPage,
        totalInvoices,
        excel,
        totalInvoices1,
        totalInvoices2,
        totalInvoices3,
        totalInvoices4,
        totalInvoices5,
        params,
        dataMeta,
        perPageOptions,
        searchQuery,
        searchStartDate,
        searchEndDate,
        license,round,
        searchStatus,
        searchRout,
        sortBy,
        isSortDirDesc,
        refInvoiceListTable,
        check_status_payment,
        statusFilter,

        resolveInvoiceStatusVariantAndIcon,
        resolveClientAvatarVariant,

        refetchData,
    }
}
