<template>
  <!-- Table Container Card -->

  <b-overlay v-if="show == false" :show="show" rounded="sm" no-fade>
    <div>
      <b-card no-body>
        <div class="m-2">
          <div class="container mt-3">
          <div class="row">
            <div class="col-sm">
              <b-card
                bg-variant="primary"
                text-variant="white"
                header="จำนวนสินค้า(เล็ก)"
                class="text-center"
              >
                <b-card-text style="font-size: 34px">{{
                  totalInvoices2
                }}</b-card-text>
              </b-card>
            </div>
            <div class="col-sm">
              <b-card
                bg-variant="secondary"
                text-variant="white"
                header="จำนวนสินค้า(กลาง)"
                class="text-center"
              >
                <b-card-text style="font-size: 34px">
                  {{ totalInvoices3 }}</b-card-text
                >
              </b-card>
            </div>
            <div class="col-sm">
              <b-card
                bg-variant="success"
                text-variant="white"
                header="จำนวนสินค้า(ใหญ่)"
                class="text-center"
              >
                <b-card-text style="font-size: 34px">{{
                  totalInvoices4
                }}</b-card-text>
              </b-card>
            </div>
            <div class="col-sm">
              <b-card
                style="background-color: orange"
                text-variant="white"
                header="จำนวนสินค้า(รวม)"
                class="text-center"
              >
                <b-card-text style="font-size: 34px">{{
                  totalInvoices5
                }}</b-card-text>
              </b-card>
            </div>
          </div>
        </div>
          <!-- tab 1 -->
          <b-row style="margin-top: 10px; margin-left: 10px">
            <b-col cols="12" md="10">
              <div
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <b-form-datepicker
                  id="datepicker-dateformat1"
                  class="col-3 mr-2"
                  v-model="searchStartDate"
                  type="text"
                  placeholder="วันที่เริ่มต้น"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  locale="en"
                ></b-form-datepicker>

                <b-form-datepicker
                  id="datepicker-dateformat2"
                  class="col-3 mr-2"
                  v-model="searchEndDate"
                  type="text"
                  placeholder="วันที่สิ้นสุด"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  locale="en"
                ></b-form-datepicker>

                <!-- <b-form-input
                            v-model="searchRout"
                            class="d-inline-block mr-2 col-3"
                            placeholder="สาย..."
                        /> -->

                <v-select
                  v-model="round"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="Optionsrob"
                  class="col-3 mr-2"
                  placeholder="รอบ"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>

                <b-button
                  class="ml-2 col-2"
                  variant="secondary"
                  @click="ClearData"
                >
                  เคลียร์ข้อมูล
                </b-button>
                <!-- {{excel}} -->
                <b-button class="ml-2" variant="success">
                  <download-excel :data="excel" :fields="fields" name="จัดรถ">
                    ดาวน์โหลด
                  </download-excel>
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row style="margin-top: 10px; margin-left: 10px">
            <b-col cols="12" md="10" class="mt-2">
              <div
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <v-select
                  v-model="searchStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  class="col-4 mr-2"
                  placeholder="สถานะ"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>

                <v-select
                  v-model="statusFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options"
                  class="col-8 mr-2"
                  placeholder="ชื่อร้านค้า"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>

                <!-- <v-select
    v-model="license"
    multiple
    label="title"
    :options="options_carLicense"
    style="padding-right: 0rem; padding-left: 0rem"
    class="col-6"
    placeholder="ทะเบียนรถ"
    :selectable="() => license.length < 10"
  /> -->

                <!-- <v-select
                  v-model="license"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options_carLicense"
                  class="col-3 mr-2"
                  placeholder="ทะเบียนรถ"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select> -->
              </div>
            </b-col>
          </b-row>

          <b-row style="margin-top: 10px; margin-left: 10px">
            <b-col cols="12" md="10" class="mt-2">
              <div
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <!-- <v-select
                  v-model="searchStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  class="col-3 mr-2"
                  placeholder="สถานะ"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select> -->

                <!-- <v-select
                  v-model="statusFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options"
                  class="col-7 mr-2"
                  placeholder="ชื่อร้านค้า"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select> -->

                <v-select
                  v-model="license"
                  multiple
                  label="title"
                  :options="options_carLicense"
                  style="padding-right: 0rem; padding-left: 0rem"
                  class="col-8"
                  placeholder="ทะเบียนรถ"
                  :selectable="() => license.length < 10"
                  
                />

                <!-- <v-select
                  v-model="license"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options_carLicense"
                  class="col-3 mr-2"
                  placeholder="ทะเบียนรถ"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select> -->
              </div>
            </b-col>
          </b-row>
        </div>

        <template>
          <div>
            <b-modal ref="my-modal2" hide-footer title="บันทึก">
              <div class="d-block">
                <h6>กรุณากรอกข้อมูลให้ครบ</h6>
              </div>
              <div class="modal-footer">
             
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hideModal"
                >
                  ปิด
                </button>
              </div>
            </b-modal>
          </div>
        </template> 

        <template>
          <div>
            <b-modal ref="my-modal1" hide-footer title="อัพเดท">
              <div class="d-block">
                <h6>คุณต้องการอัพเดทข้อมูลหรือไม่</h6>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="update_invoice(updated.invoice, updated)"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hideModal"
                >
                  ปิด
                </button>
              </div>
            </b-modal>
          </div>
        </template>

        <!-- modal trigger button -->
        <!-- <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-1
        variant="outline-primary"
      >
        Basic Modal
      </b-button>

    
    </div> -->

        <b-modal ref="my-modal" hide-footer title="หลักฐานการชำระเงิน">
          <b-card-text>
            <h5> รายละเอียด</h5>
            <b-form-input
              v-model="formData_cancle.cancel_remark"
              placeholder="สาเหตุที่ยกเลิก"
              type="text"
            ></b-form-input>
          </b-card-text>
          <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
          <b-button
            class="mt-2"
            v-if="formData_cancle.cancel_remark"
            variant="success"
            block
            @click="submit_cancel_invoice()"
            >ตกลง</b-button
          >
          <b-button class="mt-2" v-else variant="success" block disabled
            >ตกลง</b-button
          >
        </b-modal>

        <!-- basic modal -->

        <!-- <b-modal
      id="modal-1"
      title="หมายเหตุ"
    
    >
      <b-card-text>
        <h5> รายละเอียด</h5>
        <b-form-input
        v-model="formData_cancle.cancel_remark"
        placeholder="สาเหตุที่ยกเลิก"
        type="text"
        ></b-form-input>
      </b-card-text>

      <template #modal-footer>
      <div>
        {{  formData_cancle.cancel_remark }}
        <b-button disabled size="lg" class="btn.disabled-max-min">Also Disabled</b-button>
        <b-button  v-if="formData_cancle.cancel_remark !== ``"  variant="success"  @click="submit_cancel_invoice()">ตกลง</b-button>
        <b-button  v-else  variant="success"  disabled >ตกลง</b-button>
       
      </div>
      </template>
    </b-modal> -->

        <!-- tab 2 -->

        <!-- {{ update_data }} -->
        <!-- {{ totalInvoices1 }} -->
        <!-- {{ fetchInvoices }}  -->
        <!-- {{ fetchInvoices() }} -->
        <!-- <h1>Skor :  {{fetchInvoices[0].item}}</h1> -->

        <b-table
          ref="refInvoiceListTable"
          :items="fetchInvoices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="a"
        >
          <template #head(invoiceStatus)>
            <feather-icon icon="TrendingUpIcon" class="mx-auto" />
          </template>

          <template #head(route)>
            <div style="text-align: left">สาย</div>
          </template>

          <template #head(shop_name)>
            <div style="text-align: left">ร้านค้า</div>
          </template>

          <template #cell(index)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'shop_name' ? '100%' : '100%',
              }"
            >
              <span>
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'shop_name' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>
          <template #cell(order_date)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'order_date' ? '120px' : '150px',
              }"
            >
              <span style="color: green">
                {{ data.value }} </span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'order_date' ? '120px' : '150px',
              }"
            >
              -
            </div>
          </template>

          <template #cell(size_small)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'size_small' ? '100%' : '100%',
              }"
            >
              <span style="color: green">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'size_small' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(size_middle)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'size_middle' ? '100%' : '100%',
              }"
            >
              <span style="color: green">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'size_middle' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(size_large)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'size_large' ? '100%' : '100%',
              }"
            >
              <span style="color: green">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'size_large' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(total)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'total' ? '100%' : '100%',
              }"
            >
              <span style="color: blue">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'total' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(invoice)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'invoice' ? '100%' : '100%',
              }"
            >
              <span> {{ data.value }} </span>
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'invoice' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(status_main)="data">
            <div v-if="data.item.checkpage == ``">-</div>
            <div v-else>
              <div
                v-if="data.value !== ``"
                :style="{
                  width: data.value.key === 'status_main' ? '100%' : '100%',
                }"
              >
                <button>
                  <b> {{ data.value }} </b>
                </button>
              </div>
              <div
                v-else
                :style="{
                  width: data.value.key === 'status_main' ? '100%' : '100%',
                }"
              >
                <b-button
                  v-if="data.item.status !== `ยกเลิก`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-1
                  variant="danger"
                  @click="cancle_invoice(data.item)"
                >
                  ยกเลิก
                </b-button>
                <!-- <b-button
                  v-else
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-1
                  variant="danger"
                  @click="cancle_invoice(data.item)"
                  disabled
                >
                  ยกเลิก2
                </b-button> -->
                <!-- <b-button variant="danger"> ยกเลิก </b-button> -->
              </div>
            </div>
          </template>

          <!-- <template #cell(transfer_zone)="data">
                <div
                    v-if="data.value !== ``"
                    style="text-align: start;"
                    :style="{
                        width:
                            data.value.key === 'transfer_zone'
                                ? '150px'
                                : '150px',
                    }"
                >
                    <span>
                        <b> {{ data.value }} </b></span
                    >
                </div>
                <div
                    v-else
                    style="text-align: center;"
                    :style="{
                        width:
                            data.value.key === 'transfer_zone'
                                ? '120px'
                                : '150px',
                    }"
                >
                    -
                </div>
            </template> -->

          <template #cell(license)="data">
            <div
            
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'license' ? '150px' : '150px',
              }"
            >
            <!-- <div class="element"> -->
              <!-- <v-select :options="[{label: 'Canada', code: 'ca'},{label: 'Canada', code: 'ca'}]"></v-select> -->
              <!-- <v-select :options="[{label: 'Canad88888888888888888aCanad88888888888888888a', code: 'ca'},{label: 'Canada', code: 'ca'}]"  append-to-body :calculate-position="withPopper" /> -->

              <v-select
                v-model="data.item.license"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="options_carLicense"
                class="col"
                placeholder="ทะเบียน"
                append-to-body
                
                :calculate-position="withPopper"
                style="padding-right: 0rem; padding-left: 0rem; "
                @input="(value) => saveLicense(data.item.license, data.item.id)"
              >
                <template #selected-option="{ label }" >
                  <span class="text-truncate">
                    {{ label }} 
                  </span>
     
                </template>
              </v-select> 
    <!-- </div> -->
              <!-- <v-select
                v-model="data.item.license"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="options_carLicense"
                class="col"
                placeholder="ทะเบียน"
                style="padding-right: 0rem; padding-left: 0rem; "
                @input="(value) => saveLicense(data.item.license, data.item.id)"
              >
                <template #selected-option="{ label }" >
                  <span class="text-truncate " >
                    {{ label }} 
                  </span>
     
                </template>
              </v-select> -->
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'license' ? '100px' : '100px',
              }"
            >
              -
            </div>
          </template>

          <template #cell(round)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'round' ? '120px' : '120px',
              }"
             
            >
              <!-- <span>
                <b> {{ data.value }} </b></span
              > -->

              <v-select
                v-model="data.item.round"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="Optionsrob"
                class="col-12"
                placeholder="รอบ"
                append-to-body
                
                :calculate-position="withPoppers"
                style="padding-right: 0rem; padding-left: 0rem"
                @input="(value) => saveRound(data.item.round, data.item.id)"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'round' ? '80px' : '80px',
              }"
            >
              <!-- <v-select
                  v-model="data.item.round"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="Optionsrob"
                  class="col-12"
                  placeholder="รอบ"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select> -->
            </div>
          </template>

          <template #cell(shop_name)="data">
            <div
              :title="data.item.cust_remark"
              v-b-tooltip.hover
              t
              v-if="data.value !== ``"
              style="text-align: start"
              :style="{
                width: data.value.key === 'shop_name' ? '150px' : '150px',
              }"
            >
              <span>
                {{ data.value }}
              </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'shop_name' ? '150px' : '150px',
              }"
            >
              -
            </div>
          </template>

          <template #cell(transfer_zone)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: start"
              :style="{
                width: data.value.key === 'transfer_zone' ? '95px' : '95px',
              }"
            >
              <span>
                {{ data.value }}
              </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'transfer_zone' ? '95px' : '95px',
              }"
            >
              -
            </div>
          </template>

          <template #cell(transfer_date)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'transfer_date' ? '120px' : '150px',
              }"
            >
              <span style="color: green">
                {{ data.value }}
              </span>
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'transfer_date' ? '120px' : '150px',
              }"
            >
              -
            </div>
          </template>
          <template #cell(route)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: left"
              :style="{
                width: data.value.key === 'route' ? '95px' : '95px',
              }"
            >
              <span>
                {{ data.value }}
              </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'route' ? '95px' : '95px',
              }"
            >
              -
            </div>
          </template>

          <!-- <template #cell(route)="data">
                <div
                    v-if="data.value !== ``"
                    :style="{
                        width: data.value.key === 'shop_name' ? '200px' : '200px',
                    }"
                >
                    <span>
                        <b> {{ data.value }} </b>
                    </span>
                </div>
                <div
                    v-else
                    :style="{
                        width:
                            data.value.key === 'order_date' ? '200px' : '200px',
                    }"
                >
                    -
                </div>
            </template> -->

          <template #cell(shop_code)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'shop_code' ? '80px' : '80px',
              }"
            >
              <b> {{ data.value }} </b>
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'order_date' ? '80px' : '80px',
              }"
            >
              -
            </div>
          </template>

          <template #cell(remark)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'shop_code' ? '80px' : '80px',
              }"
            >
              {{ data.value }}
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'order_date' ? '80px' : '80px',
              }"
            >
              -
            </div>
          </template>

          <!-- Column: Issued Date -->
          <template #cell(status_order)="data">
            <div
              :style="{
                width: data.value.key === 'shop_name' ? '50px' : '50px',
              }"
            >
              <span class="text-nowrap">
                <div v-if="data.value == 'ปกติ'" style="color: green">ปกติ</div>
                <div v-else-if="data.value == 'ด่วนมาก'" style="color: red">
                  ด่วนมาก
                </div>
                <div v-else style="color: #b83f00">ด่วน</div>
              </span>
            </div>
          </template>

          <template #cell(status)="data">
            <div
              :style="{
                width: data.value.key === 'status' ? '50px' : '50px',
              }"
            >
              <span class="text-nowrap">
                <div v-if="data.value == 'ส่งแล้ว'" style="color: green">
                  ส่งแล้ว
                </div>
                <div v-else-if="data.value == 'ยกเลิก'" style="color: red">
                  ยกเลิก
                </div>
                <div v-else>
                  {{ data.value }}
                </div>
              </span>
            </div>
          </template>

          <template #cell(actions)="row">
            <!-- <div class="media d-flex align-items-center"> -->
            <!-- <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6> -->

            <!-- </div> -->

            <!-- <b-button size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? "Hide" : "Edit" }}
                </b-button > -->

            <!-- {{ row.item.checkbox }} -->
            <!-- {{ row.item }} -->
            <div v-if="row.item.checkpage == ``">-</div>
            <div v-else>
              <!-- <b-form-checkbox
                v-if="row.item.status == `ยกเลิก`"
                v-model="row.item.checkbox"
                name="check-button"
                switch
                @input="(value) => UpdateQ(row.item.checkbox, row.item)"
                @change="row.toggleDetails"
                disabled="disabled"
              >
              </b-form-checkbox> -->
              <div v-if="row.item.status == `ยกเลิก`"></div>
              <b-form-checkbox
                v-else
                v-model="row.item.checkbox"
                name="check-button"
                switch
                @input="(value) => UpdateQ(row.item.checkbox, row.item)"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </div>

            <!-- <template v-slot:item.is_active="{ item }"> -->
            <!-- <switch-toggle :value="item.is_active" @input="(value) => setItemActive(item.id, value)"/> -->
            <!-- </template> -->

            <!-- <b-form-checkbox

@click="ClearData"
      
          switch
        /> -->
          </template>

          <template #row-details="row">
            <b-card>
              <b-row style="margin-top: 10px; margin-left: 10px">
                <b-col cols="12" md="10">
                  <div
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <!-- {{ row }} -->
                    <!-- <div > -->
                    <!-- <b-form-input
                      v-if="row.item.invoice != ``"
                      class="col-1 ml-1"
                      v-model="row.item.invoice"
                      placeholder="Enter invoice"
                      type="text"
                      disabled
                    ></b-form-input> -->

                    <b-form-input
                      class="col-2 ml-2"
                      v-model="row.item.invoice"
                      placeholder="Enter invoice"
                      type="text"
                      disabled
                    ></b-form-input>

                    <!-- <b-form-input
                      v-if="row.item.invoice != ``"
                      class="col-1 ml-1"
                      v-model="row.item.invoice"
                      placeholder="Enter invoice"
                      type="text"
                      disabled
                    ></b-form-input>

                    <b-form-input
                      v-else
                      class="col-1 ml-1"
                      v-model="row.item.invoice"
                      placeholder="Enter invoice"
                      type="text"
                    ></b-form-input> -->

                    <!-- </div> -->

                    <b-form-input
                      class="col-2 ml-2"
                      v-model="row.item.amount"
                      placeholder="Enter amount"
                      type="number"
                      disabled
                    ></b-form-input>

                    <!-- <div> -->
                    <!-- <b-form-input
                      v-if="row.item.amount != ``"
                      class="col-1 ml-1"
                      v-model="row.item.amount"
                      placeholder="Enter amount"
                      type="number"
                      disabled
                    ></b-form-input>

                    <b-form-input
                      v-else
                      class="col-1 ml-1"
                      v-model="row.item.amount"
                      placeholder="Enter amount"
                      type="number"
                    ></b-form-input> -->
                    <!-- </div> -->

                    <!-- ppppp : {{ check_status_payment }} -->

                    <!-- {{ updated }} -->

                    <!-- <b-button
                      v-if="row.item.check_status_payment == true"
                      class="col-2 ml-2"
                      variant="success"
                      disabled
                    >
                      อัพเดท
                    </b-button> -->
                    <b-button
                      class="col-2 ml-2"
                      variant="success"
                      @click="check(row.item.invoice, row.item)"
                   
                    >
                      จัดส่งเรียบร้อยแล้ว
                    </b-button>

                    <!-- <b-button
                      v-if="row.item.amount != `` &&  row.item.invoice != `` || row.item.invoice != null"
                      class="col-1 ml-1"
                      variant="success"
                      @click="update_invoice(row.item.invoice, row.item)"
                    
                    >
                      อัพเดท
                    </b-button>
                    <b-button
                      v-else
                      class="col-1 ml-1"
                      variant="success"
                      @click="update_invoice(row.item.invoice, row.item)"
                     
                    >
                      อัพเดท
                    </b-button> -->
                  </div>
                </b-col>
              </b-row>
              <!-- 1 -->
              <!-- <div class="container"> -->
              <!-- <div class="row"> -->
              <!-- <div class="col-6">
                                INVOICE
                                <b-form-input
                                class="col-2"
                                    v-model="invoice"
                                    placeholder="Enter your invoice"
                                    type="text"
                                ></b-form-input>
                                <b-form-input
                                class="col-3"
                                    v-model="amount"
                                    placeholder="Enter your amount"
                                    type="number"
                                ></b-form-input>
                            </div> -->

              <!-- <div class="col-2">
                                <b-button
                                    class="ml-6 col-6 mt-2"
                                    variant="success"
                                    @click="update_invoice(invoice, row.item)"
                                >
                                    Update
                                </b-button>
            
                            </div> -->
              <!-- <div class="col-2"> 
                                
                                <b-button  class="ml-6 col-6 mt-2" variant="success"> Update </b-button>
                          
                            </div>  -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- <ul>
                        <li v-for="(value, key) in row.item" :key="key">
                            {{ key }}: {{ value }} test 
                        </li>
                    </ul> -->
            </b-card>

            <!-- <table border="0" cellpadding="0" cellspacing="0">
           
            <tr height="16" hidden>
                <td colspan="16" v-for="(value, key) in row.item" :key="key">
                
                   {{ key }}
                </td>
             
            </tr>
            <tr height="16" >
                <td colspan="16" v-for="(value, key) in row.item" :key="key">
                    {{ value }} 
                </td>
              
            </tr>
    </table> -->

            <!-- <b-table
          
            :items="fetchInvoices"
            responsive
       
       
        >
        </b-table> -->
          </template>
        </b-table>

        <!-- <template #cell(actions1)="data">
            <div class="text-nowrap">
                <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="SendIcon"
                    class="cursor-pointer"
                    size="16"
                />
            </div> </template> -->
        <!-- </b-card> -->
        <!-- </template> -->


        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
           
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="searchEndDate1"
                :total-rows="totalInvoices"
                :per-page="currentPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BCollapse,
  BFormCheckbox,
  BFormCheckboxGroup,
  BModal,
  VBModal,
  BAlert,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useInvoicesList from "./useInvoiceList";
import axios from "axios";
import invoiceStoreModule from "../invoiceStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormDatepicker,
    vSelect,
    flatPickr,
    BCollapse,
    BFormCheckbox,
    BFormCheckboxGroup,
    BModal,
    BAlert,
    BCardText,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  computed: {
    options_carLicense: () => options_carLicense,
  },
  data() {
    return {
      fields: {
        No: "index",
        ทะเบียนรถ: "license",
        รอบ: "round",
        สาย: "route",
        วันที่สั่งสินค้า: "order_date",
        วันที่ส่งสินค้า: "transfer_date",
        ร้านค้า: "shop_name",
        "สินค้า(เล็ก)": "size_small",
        "สินค้า(กลาง)": "size_middle",
        "สินค้า(ใหญ่)": "size_large",
        อื่นๆ: "remark",
        รวม: "total",
        ใบแจ้งหนี้: "invoice",
        จำนวนเงิน: "amount",
        สถานะ: "status",
        "อัพเดท สถานะ": "status_main",
      },
      show: false,
      options: [],
      modalShow: false,
      options_route: [],
      options_carLicense: [],
      checked: false,
      invoice: "",
      amount: 0,
      updated: "",
      formData_cancle: {
        id: "",
        cancel_remark: ``,
        order_code: "",
        size_small: "",
        size_middle: "",
        size_large: "",
      },
      formData_Order: {
        size_small: 0,
        size_middle: 0,
        size_large: 0,
        total: 0,
      },
      items: [
        {
          isActive: true,
          age: 40,
          name: { first: "Dickerson", last: "Macdonald" },
        },
        {
          isActive: false,
          age: 21,
          name: { first: "Larsen", last: "Shaw" },
        },
        {
          isActive: false,
          age: 9,
          name: { first: "Mini", last: "Navarro" },
          _rowVariant: "success",
        },
        {
          isActive: false,
          age: 89,
          name: { first: "Geneva", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "Jami", last: "Carney" },
        },
        {
          isActive: false,
          age: 27,
          name: { first: "Essie", last: "Dunlap" },
        },
        {
          isActive: true,
          age: 40,
          name: { first: "Thor", last: "Macdonald" },
        },
        {
          isActive: true,
          age: 87,
          name: { first: "Larsen", last: "Shaw" },
          _cellVariants: { age: "danger", isActive: "warning" },
        },
        {
          isActive: false,
          age: 26,
          name: { first: "Mitzi", last: "Navarro" },
        },
        {
          isActive: false,
          age: 22,
          name: { first: "Genevieve", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "John", last: "Carney" },
        },
        {
          isActive: false,
          age: 29,
          name: { first: "Dick", last: "Dunlap" },
        },
      ],
      // fields: [
      //   {
      //     key: "name",
      //     label: "Person full name",
      //     sortable: true,
      //     sortDirection: "desc",
      //   },
      //   {
      //     key: "age",
      //     label: "Person age",
      //     sortable: true,
      //     class: "text-center",
      //   },
      //   {
      //     key: "isActive",
      //     label: "Is Active",
      //     formatter: (value, key, item) => {
      //       return value ? "Yes" : "No";
      //     },
      //     sortable: true,
      //     sortByFormatted: true,
      //     filterByFormatted: true,
      //   },
      //   { key: "actions", label: "Actions" },
      // ],
      formData: {
        searchStartDate: "",
        carLicense: "",
        searchRob: "",
      },
      totalRows: 1,
      // currentPage: 1,
      // perPage: 5,
      // pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      update_data: [],
      modal: "test : ",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      date_update: [],
    };
  },
  //     watch: {
  //         totalInvoices1: {
  //     handler(newName, oldName) {
  //       console.log('obj.a changed');
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  watch: {
    totalInvoices1: {
      // console.log("")
      handler(val) {
        // console.log(val);
        // console.log("val" , val)
        // console.log("val" , val)
        this.UpdateQ(val);

        // for (let i = 0; i < val.length; i++) {
        //     if (val[i].checkbox == true) {
        //         console.log("test1");
        //         // return
        //     } else {
        //         console.log("test2");
        //     }
        // }

        // for (let i = 0; i < val.length; i++) {

        //     if(val[i].checkbox === true){

        //         // if(val[i].code == this.update_data){

        //         // }

        //         this.update_data.push( val[i])
        //         console.log("val" , val[i])
        //         return 0;

        //     }

        // this.options.push(response.data[i].shopName);
        // }
      },
      deep: true,
    },
  },
  methods: {
    withPopper (dropdownList, component, {width}) {
    dropdownList.classList.add("style-chooser");
    // ...  adjust the position
  },
  withPoppers (dropdownList, component, {width}) {
    dropdownList.classList.add("style-choosers");
    // ...  adjust the position
  },

    scrollWin() {
  window.scrollTo(0, 100);
},
  
    check(e, x) {
      this.$refs["my-modal1"].show();
      this.updated = x;
      console.log(this.updated);
      // this.update_invoice();
    },
    hideModal() {
      // this.$refs["submit"].hide();
      this.$refs["my-modal1"].hide();
      this.$refs["my-modal2"].hide();
      // this.$refs["my-modal2"].hide();
    },
    async CheckRegister() {
      let payload = {
        flag: "",
      };
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listshop`,
          payload
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options.push(response.data[i].shopName);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },

    async getCar() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listCar`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options_carLicense.push(response.data[i].carLicense);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    // async getOrder() {
    //   let payload = {
    //     dateFrom: "",
    //     dateTo: "",
    //     status: "",
    //     statusMain: "รอจัดรถ",
    //     transferZone: "",
    //     shopName: "",
    //   }

    //   try {
    //     const response = await axios.post(
    //       `http://202.129.206.107:11009/listtransfer`,
    //       payload
    //     );

    //     console.log("response55");
    //     let max_small = 0;
    //     let max_middle = 0;
    //     let max_large = 0;

    //     for (let i = 0; i < response.data.length; i++) {
    //       //  console.log("response1" ,response.data)
    //       max_large = max_large + response.data[i].size_large;
    //       max_middle = max_middle + response.data[i].size_middle;
    //       max_small = max_small + response.data[i].size_small;
    //     }

    //     // console.log("a", a);

    //     this.formData_Order.max_small = max_small;

    //     this.formData_Order.max_middle = max_middle;

    //     this.formData_Order.max_large = max_large;

    //     this.formData_Order.total = max_large + max_middle + max_small;

    //     //    response.data;

    //     // for (let i = 0; i < response.data.length; i++) {
    //     //   this.options_carLicense.push(response.data[i].carLicense);
    //     // }

    //     // return todoItems;
    //   } catch (errors) {
    //     console.error(errors);
    //   }
    // },
    async getBranch() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listBranch`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          // this.options.push(response.data[i].shopName);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    async saveLicense(e, id) {
      let payload = {
        id: id,
        license: e,
      };

      if(payload.license == null) {
        this.$refs["my-modal2"].show();
      }{
        try {
        const response = await axios.post(
          `http://202.129.206.107:11009/savelicense`,
          payload
        );

        if (response.data.status == "Save success") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `อัพเดทรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
          // window.location.reload();
        }
      } catch (errors) {
        console.error(errors);
      }
      }

    
    },
    async saveRound(e, id) {
      let payload = {
        id: id,
        carRound: e,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/saveround`,
          payload
        );

        if (response.data.status == "Save success") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `อัพเดทรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
          // window.location.reload();
        }
      } catch (errors) {
        console.error(errors);
      }
    },
    async getRoute() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listRoute`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options_route.push(response.data[i].route);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },

    async UpdateQ(e, items) {
      //   console.log("e555", e, items);

      // this.date_update = e;

      this.update_data = e;

      //   console.log("this.update_datatttttttttttttttttttt", this.update_data);
      //   console.log("this.date_update ", this.date_update);

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      // //             }
      //         console.log("test" ,e ,x )

      //         if(e == true ){

      //             this.update_data.push(x)
      //         }else{

      //             //เช็ค x.code ว่ามีในนี้ไหม  update_data

      //             for (let i = 0; i < this.update_data.length; i++) {
      //                 console.log("x.code ", x.code  , this.update_data[i].code)

      //                 if(x.code == this.update_data[i].code){
      //                     console.log("1")
      //                     // this.update_data.splice(this.update_data.indexOf( x.code  ), 1);
      //                 }else{
      //                     console.log("2")
      //                 }

      //                 // this.options.push(response.data[i].shopName);
      //             }

      //             //ถ้ามี ลบ update_data.code
      //         console.log("555",this.update_data)

      //         }

      //         console.log("update_data", this.update_data)

      // try {
      //     const response = await axios.post(
      //         `http://202.129.206.107:11009/listshop`
      //     );

      //     console.log("response", response);

      //     for (let i = 0; i < response.data.length; i++) {
      //         this.options.push(response.data[i].shopName);
      //     }

      // } catch (errors) {
      //     console.error(errors);
      // }
    },
    async cancle_invoice(items) {
      this.formData_cancle = items;
      this.formData_cancle.cancel_remark = "";
      this.$refs["my-modal"].show();
      //   console.log("items", items);
    },

    async submit_cancel_invoice() {
      this.show = true;

      //             - id (id invoice)
      // - cancel_remark
      // - order_code
      // - size_small
      // - size_middle
      // - size_large

      let payload = {
        id: this.formData_cancle.id,
        cancel_remark: this.formData_cancle.cancel_remark,
        order_code: this.formData_cancle.order_code,
        size_small: this.formData_cancle.size_small,
        size_middle: this.formData_cancle.size_middle,
        size_large: this.formData_cancle.size_large,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/cancelinvoice`,
          payload
        );

        // console.log("response", response.data.status);
        // window.location.reload();

        if (response.data.status == "Save success") {
          this.ClearData();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `ยกเลิกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
          // window.location.reload();
        }

        // for (let i = 0; i < response.data.length; i++) {
        //     this.options.push(response.data[i].shopName);
        // }
      } catch (errors) {
        console.error(errors);
      }

      this.$refs["my-modal"].hide();
    },
    async Update() {
      this.show = true;
      // console.log("e555" , e)

      // this.update_data = e

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      //             }
      // console.log("test" , totalInvoices1)

      //     console.log("this.update_data",   this.update_data)

      let b = [];

      // this.date_update

      //   console.log("this.update_data", this.date_update);

      for (var i = 0; i < this.update_data.length; i++) {
        if (this.update_data[i].checkbox == true) {
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          b.push(this.update_data[i]);
        } else {
          //   console.log("2");
        }
        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      //      console.log("this.update_data[i] " , this.update_data[i] )

      // let test = {
      //     id: 18,
      //     code: "P00005",
      //     orderDate: "2023-03-23 00:35:35",
      //     dueDate: null,
      //     transferDate: "2023-03-23 00:35:35",
      //     custCode: "6603002",
      //     sizeSmall: "12455",
      //     sizeMiddle: 1881,
      //     sizeLarge: 1112,
      //     remark: "ttyy",
      //     total: 4238,
      //     statusOrder: "ปกติ",
      //     statusMain: "รอจัดรถ",
      //     updateDate: "2023-03-29 14:19:48",
      //     lineId: "Uafc47e26b9bb051412f9ecbf0c06346b",
      //     chatId: "U65410582defe7d3b679c62670ad6de44",
      // };
      //   console.log(" b", b);
      // console.log(" test", b);

      // if(e == true ){

      //     this.update_data.push(x)
      // }

      let payload = {
        transferDate: this.formData.searchStartDate,
        license: this.formData.carLicense,
        round: this.formData.searchRob,
        staffId: 1,
        row: b,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/savetransfer`,
          payload
        );

        // console.log("response", response.data.status);
        // window.location.reload();

        if (response.data.status == "Save success") {
          //   console.log("save");
          this.ClearData();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          this.show = false;
          // window.location.reload();
        }

        // for (let i = 0; i < response.data.length; i++) {
        //     this.options.push(response.data[i].shopName);
        // }
      } catch (errors) {
        console.error(errors);
      }
    },

    async update_invoice(e, items) {
      this.show = true;
      //   console.log("e", e, "items", items);

      let payload = {
        chat_id: items.chat_id,
        invoice: items.invoice,
        id: items.id,
        remark: items.remark,
        size_large: items.size_large,
        size_middle: items.size_middle,
        size_small: items.size_small,
        amount: items.amount,
      };

      //   console.log("payload" , payload)
      //   console.log("items" , items)

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/saveinvoice`,
          payload
        );

        // console.log("response", response.data.status);
        // window.location.reload();

        if (response.data.status == "Save success") {
          //   console.log("response save inviod");
          // this.ClearData();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `อัพเดทรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    ClearData() {
      this.show = true;
      //   console.log("1", this.searchStartDate);
      this.searchStartDate = "";
      this.searchEndDate = "";
      this.searchRout = "";
      this.searchStatus = "รอจัดส่ง";
      this.searchRoute = "";
      this.statusFilter = "";
      this.license = "";
      this.round = "";
      this.show = false;
    },
    ClearDataUpdate() {
      this.formData = {
        searchStartDate: "",
        carLicense: "",
        searchRob: "",
      };
      // console.log("1", this.searchStartDate);
      // this.searchStartDate = "";
      // this.searchEndDate = "";
      // this.searchRout = "";
      // this.searchStatus = "";
      // this.searchRoute = "";
      // this.statusFilter = "";
    },
    total() {
      //   console.log("test");
    },
  },
  mounted() {
    this.CheckRegister();
    this.getCar();
    this.getBranch();
    this.getRoute();
    this.getOrder();

    // console.log("check_status_payment" , this.check_status_payment)

    // console.log("1");
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    // const statusOptions = [
    //   'Downloaded',
    //   'Draft',
    //   'Paid',
    //   'Partial Payment',
    //   'Past Due',
    // ]

    const statusOptions = ["ยกเลิก", "ส่งแล้ว", "รอจัดส่ง"];
    const Optionsrob = ["1", "2", "3", "4", "5"];

    // const statusOptionsshop = [
    // ]

    const {
      fetchInvoices,
      excel,
      fetch_shop,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      totalInvoices5,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchStartDate,
      searchEndDate,
      license,
      searchEndDate1,
      round,
      searchStatus,
      searchRob,
      searchRout,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      params,
      statusFilter,
      check_status_payment,
      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList();

    return {
      fetchInvoices,
      fetch_shop,
      tableColumns,
      perPage,
      excel,
      currentPage,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      totalInvoices5,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      searchStartDate,
      searchEndDate,
      searchEndDate1,
      license,
      round,
      searchStatus,
      searchRob,
      searchRout,
      statusFilter,
      //   statusOptionsshop,
      Optionsrob,
      refetchData,
      params,
      statusOptions,
      check_status_payment,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
[dir="ltr"] .vs__actions {
  padding: 0px 0px 0 0px !important;
}

[dir] .dropdown-menu {
  // background-color: red;
  padding: 0px 0px 0 0px !important;
}

// .table-responsive {
//     display: block;
//     width: 100%;
//     overflow-x: clip;
//      overflow-y: auto;
//     -webkit-overflow-scrolling: touch;
// background-color: rgb(150, 21, 21); 
  //  width: 500px;  
  //  height: 50px;  
  //  border: 1px solid black;   
  //  overflow-x: hidden;  
  //  overflow-y: scroll; 
//  }



.hidden_header {
  display: none;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

// .invoice-filter-select[data-v-5cb60a6f] {
//     min-width: 213px;
// }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// table, td, th {
//   border: 1px solid black;
// }

// .select .v-input__control {
//   overflow: hidden;
// }

.style-chooser.vs__dropdown-menu {
    // background: #ff1900  !important;
    // border: none;
    // color: #fcfcfc  !important;
    // text-transform: lowercase;
    // font-variant: small-caps;
    width:200px;
height:200px;
position:fixed;
top:40%;
left:35%;
    // overflow-x: auto;
  }


  .style-choosers.vs__dropdown-menu {
    // background: #ff1900  !important;
    // border: none;
    // color: #fcfcfc  !important;
    // text-transform: lowercase;
    // font-variant: small-caps;
    width:200px;
height:200px;
position:fixed;
top:40%;
left:45%;
    // overflow-x: auto;
  }
// .vs__dropdown-menu{
// background: red;
// }

// a {
//   display: block;
//     width: 100%;
//     overflow-x: auto;
// }


[dir] .custom-control-input[disabled] ~ .custom-control-label::before,
[dir] .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #dad6d6;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #d1d0d3;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  // padding: 0.72rem 2rem;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 0.3rem;
}

// table {
  //   border-collapse: collapse;
  // width: 120%;
// }

// th {
//   height: 70px;
// }

// .element {
  // position: absolute;
  // top: 0;
  // right: 100%;
  // background-color: CornflowerBlue;
  // padding: 10px;
  // width: 200px;
// }

</style>
